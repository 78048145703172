<template>
  <fade>
    <floating-toolbar
      class="save-bar bottom"
      :class="show ? 'modified' : ''"
      :modified="show"
      v-if="show"
    >
      <slot><div>&nbsp;</div></slot>
      <span class="badge info mr-2" v-if="checkingOutOldVersion" style="margin-left: auto">
        You are checking out an old version. Save to revert to this version.
      </span>
      <save-or-cancel
        ref="saveBtn"
        :changes="changes"
        :loading="loading"
        :isDirty="show"
        :omitStyle="true"
        @cancel="cancelClick"
        @save="saveClick"
      >
      </save-or-cancel>
    </floating-toolbar>
  </fade>
</template>

<script>
import SaveOrCancel from '../../ui/SaveOrCancel.vue'
import FloatingToolbar from '../../ui/FloatingToolbar.vue'

export default {
  emits: ['save', 'cancel'],
  methods: {
    getAndShowChanges() {
      _.log(this.changes)
    },
    saveClick() {
      this.$emit('save')
    },
    cancelClick() {
      this.$emit('cancel')
    }
  },
  computed: {
    show() {
      return (this.isDirty ? 1 : 0) + (this.checkingOutOldVersion ? 1 : 0)
    }
  },
  props: {
    checkingOutOldVersion: {
      required: false,
      default: 0
    },
    loading: {
      required: false,
      default: 0
    },
    isDirty: {
      required: true,
      default: false
    },
    changes: {
      required: false,
      default: () => ({})
    }
  },
  components: { SaveOrCancel, FloatingToolbar }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.save-bar {
  justify-content: space-between;
  z-index: 999;
  text-align: right;
  -webkit-transition: all 500ms;
  transition: all 500ms;

  &.bottom {
    top: auto !important;
    left: 0px;
    right: 0;
    width: auto;
    bottom: 0px;
    position: fixed;
    box-shadow: 0px -20px 20px -20px rgba(115, 120, 123, 0.4);
    margin-bottom: 0;

    @media (max-width: 992px) {
      left: 0px;
    }
  }
}
</style>
